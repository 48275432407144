import './App.css';
import Main from "./components/Main";
import {BrowserRouter} from "react-router-dom";

function App() {
  return (
      <BrowserRouter>
      <Main/>
      </BrowserRouter>);
}

export default App;
