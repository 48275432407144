import {useAuth0} from "@auth0/auth0-react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useConfig} from "../hooks/useConfig";


export function ItemCreateRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [itemCodeId, setItemCodeId] = useState("");
    const [itemType, setItemType] = useState("");

    const fetchItemCreate = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/item/create`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify({
                    itemCodeId,
                    itemType
                })
            });

            if ( !response.ok ) {
                throw response;
            }

            const { clientId: itemId } = await response.json();
            console.log(`Created item id [${itemId}]`);

            navigate( `/item/list`, { replace: true});

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const handleCreateButtonClicked = async () => {
        await fetchItemCreate();
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Nuevo Item
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Código"
                            value={itemCodeId}
                            onChange={(e) => { setItemCodeId(e.target.value) } }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Tipo"
                            value={itemType}
                            onChange={(e) => { setItemType(e.target.value) } }
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={handleCreateButtonClicked}>Crear</Button>
            </Box>
        </>
    )
}