import {useAuth0} from "@auth0/auth0-react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useConfig} from "../hooks/useConfig";


export function ClientCreateRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [clientName, setClientName] = useState("");
    const [clientMail, setClientMail] = useState("");
    const [clientAddress, setClientAddress] = useState("");


    const fetchClientCreate = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/client/create`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify({
                    clientName,
                    clientMail,
                    clientAddress
                })
            });

            if ( !response.ok ) {
                throw response;
            }

            const { clientId } = await response.json();
            console.log(`Created client id [${clientId}]`);

            navigate( `/client/list`, { replace: true});

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const handleCreateButtonClicked = async () => {
        await fetchClientCreate();
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Nuevo Cliente
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            value={clientName}
                            onChange={(e) => { setClientName(e.target.value) } }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Correo"
                            value={clientMail}
                            onChange={(e) => { setClientMail(e.target.value) } }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Dirección"
                            value={clientAddress}
                            onChange={(e) => { setClientAddress(e.target.value) } }
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={handleCreateButtonClicked}>Crear</Button>
            </Box>
        </>
    )
}