import {IconButton, Toolbar, Typography} from "@mui/material";
import {AppBar as BaseAppBar} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import params from "./Params";
import {useAuth0} from "@auth0/auth0-react";


export default function AppBar() {
    const drawerWidth = params.drawerWidth;

    const { logout } = useAuth0();

    return (<BaseAppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` , backgroundColor: params.biosurColor}}
    >
        <Toolbar>
            <Typography variant="h6" noWrap component="div" style={{ flexGrow: 1}}>
                Biosur
            </Typography>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={logout}
                color="inherit"
            >
                <LogoutIcon />
            </IconButton>


        </Toolbar>
    </BaseAppBar>)
}