import {Route, Routes as BaseRoutes} from "react-router-dom";
import Home from "../routes/Home";
import {ClientCreateRoute} from "../client/ClientCreateRoute";
import {ClientListRoute} from "../client/ClientListRoute";
import {ItemListRoute} from "../item/ItemListRoute";
import {ItemCreateRoute} from "../item/ItemCreateRoute";
import {OperatorListRoute} from "../operator/OperatorListRoute";
import {OperatorCreateRoute} from "../operator/OperatorCreateRoute";
import {WeeklyMaintenanceListRoute} from "../weeklyMaintenance/WeeklyMaintenanceListRoute";
import {PendingMaintenanceListRoute} from "../pendingMaintenance/PendingMaintenanceListRoute";

export default function Routes() {
    return (<BaseRoutes>
        <Route path="/" element={<Home/>}/>
        <Route exact path="/item/list" element={ <ItemListRoute/>}/>
        <Route exact path="/item/create" element={ <ItemCreateRoute/>}/>
        <Route exact path="/client/create" element={ <ClientCreateRoute/>}/>
        <Route exact path="/client/list" element={ <ClientListRoute/>}/>
        <Route exact path="/operator/create" element={ <OperatorCreateRoute/>}/>
        <Route exact path="/operator/list" element={ <OperatorListRoute/>}/>
        <Route exact path="/weeklyMaintenance/list" element={ <WeeklyMaintenanceListRoute/>}/>
        <Route exact path="/pendingMaintenance/list" element={ <PendingMaintenanceListRoute/>}/>

    </BaseRoutes>);
}