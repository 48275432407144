import {useAuth0} from "@auth0/auth0-react";
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router";
import {useConfig} from "../hooks/useConfig";


export function OperatorCreateRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [operatorName, setOperatorName] = useState("");
    const [operatorAuth0Id, setOperatorAuth0Id] = useState("");

    const fetchOperatorCreate = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/operator/create`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify({
                    operatorName,
                    operatorAuth0Id
                })
            });

            if ( !response.ok ) {
                throw response;
            }

            const { operatorId } = await response.json();
            console.log(`Created operator id [${operatorId}]`);

            navigate( `/operator/list`, { replace: true});

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const handleCreateButtonClicked = async () => {
        await fetchOperatorCreate();
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Nuevo Item
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            label="Nombre"
                            value={operatorName}
                            onChange={(e) => { setOperatorName(e.target.value) } }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Auth0 Id"
                            value={operatorAuth0Id}
                            onChange={(e) => { setOperatorAuth0Id(e.target.value) } }
                        />
                    </Grid>
                </Grid>
                <Button variant="contained" onClick={handleCreateButtonClicked}>Crear</Button>
            </Box>
        </>
    )
}