import { Drawer as BaseDrawer } from '@mui/material';
import {Divider, List, ListItem, ListItemIcon, ListItemText, Toolbar} from "@mui/material";
import InboxIcon from '@mui/icons-material/Inbox';
import {Link} from "react-router-dom";
import params from "./Params";

export default function Drawer() {
    const drawerWidth = params.drawerWidth;

    return (<BaseDrawer
        sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
            },
        }}
        variant="permanent"
        anchor="left"
    >
        <img src="/logo_biosur.png" style={{margin: 20}}/>
        <List>
            <ListItem button component={Link} to="/" key={"Home"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Inicio" />
            </ListItem>
            <ListItem component={Link} to="/client/list" key={"ClientList"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem component={Link} to="/item/list" key={"ItemListRoute"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Itemes" />
            </ListItem>
            <ListItem component={Link} to="/operator/list" key={"OperatorListRoute"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Operadores" />
            </ListItem>
            <ListItem component={Link} to="/pendingMaintenance/list" key={"PendingMaintenanceListRoute"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Asignar mantenciones" />
            </ListItem>
            <ListItem component={Link} to="/weeklyMaintenance/list" key={"WeeklyMaintenanceListRoute"}>
                <ListItemIcon>
                    <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Planificación" />
            </ListItem>
        </List>
    </BaseDrawer>);
}