import {Box, Toolbar} from "@mui/material";
import AppBar from "./AppBar";
import Drawer from "./Drawer";
import Routes from "./Routes";
import {withAuthenticationRequired} from "@auth0/auth0-react";

function MainApp() {
    return (
        <>
            <AppBar/>
            <Drawer/>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />
                <Routes/>
            </Box>
        </>);
}

export default withAuthenticationRequired(MainApp, {
    // Show a message while the user waits to be redirected to the login page.
    onRedirecting: () => <div>Redirecting you to the login page...</div>,
});