import {Box, CssBaseline, Typography} from "@mui/material";
import {Route, Routes as BaseRoutes} from "react-router-dom";
import {Auth0Provider} from "@auth0/auth0-react";
import {useConfig} from "../hooks/useConfig";
import MainApp from "./MainApp";
import MaintenanceTracking from "../routes/MaintenanceTracking";

export default function Main() {
    const { auth0Domain, auth0ClientId, auth0Audience } =  useConfig();

    return (
        <Auth0Provider
            domain={auth0Domain}
            clientId={auth0ClientId}
            audience={auth0Audience}
            redirectUri={window.location.origin}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <BaseRoutes>
                    <Route exact path="/maintenanceTracking/:maintenanceId" element={<MaintenanceTracking/>} />
                    <Route exact path="/*" element={<MainApp/>}/>
                </BaseRoutes>
            </Box>
        </Auth0Provider>);
}