import {
    Box, Button, Dialog, DialogTitle, Grid, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper,
    TextField,
    Typography
} from "@mui/material";
import {useConfig} from "../hooks/useConfig";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import CheckIcon from "@mui/icons-material/Check";
import {useEffect, useState} from "react";
import {useParams} from "react-router";


export default function MaintenanceTracking() {

    const { maintenanceId } = useParams();
    const { appApiEndpoint } = useConfig();
    const [maintenance, setMaintenance] =  useState(null);

    useEffect(async () => {
        try {

            let url = `${appApiEndpoint}/maintenance/${maintenanceId}/tracking`;
            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw response;
            }

            const result = await response.json();

            result.position = [parseFloat(result?.data?.latitude), parseFloat(result?.data?.longitude)];
            setMaintenance(result);
        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }


    }, []);

    return (
        maintenance ?
            <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 2, mb: 2 },
                margin: 'auto'
            }}
            noValidate
            autoComplete="off"


        >
           <div style={{display:'flex', margin: 'auto', flexDirection: "column", alignItems: 'center', maxWidth: 700, width: "100%"}}>
            <img src="/logo_biosur.png" style={{alignSelf: 'center', maxWidth: 300, width: "100%"}}/>
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemIcon>
                            <CheckIcon/>
                        </ListItemIcon>
                        <ListItemText primary={ true ? 'Realizada' : 'Pendiente'} secondary={
                            <Typography>{`Fecha : ${maintenance.maintenanceDate}`} </Typography>
                        }/>
                    </ListItemButton>
                </ListItem>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Operador"
                        value={maintenance.operatorName}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Dirección"
                        value={maintenance.address}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Fecha"
                        value={maintenance.maintenanceDate}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Código de baño"
                        value={maintenance.itemCodeId}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        multiline
                        maxRows={6}
                        label="Comentario"
                        value={maintenance.comment}
                    />
                    { maintenance.data.longitude &&
                    <MapContainer center={maintenance.position} zoom={13} dragging={false} style={{width: "100%", height: 500, marginBottom: 10}}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={maintenance.position}>
                        </Marker>
                    </MapContainer> }
                    <Paper variant="outlined">
                        <img src={`${appApiEndpoint}/maintenance/${maintenance.maintenanceId}/image/`}/>
                    </Paper>
            </div>
        </Box> : <Typography>Cargando...</Typography>

    )
}