import {
    Box, Button, Dialog, DialogTitle, Paper,
    TextField,
    Typography
} from "@mui/material";
import {useConfig} from "../hooks/useConfig";
import {useAuth0} from "@auth0/auth0-react";
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import {useEffect, useState} from "react";


export default function PendingMaintenanceDetails({pendingMaintenanceId, maintenanceId}) {

    const { appApiEndpoint } = useConfig();
    const [pendingMaintenance, setPendingMaintenance] = useState({});
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [image, setImage] = useState(``);
    const { getAccessTokenSilently} = useAuth0();

    const getMaintenanceList = () => {
        if ( !pendingMaintenance ) return [];
        if ( !pendingMaintenance.maintenanceList ) return [];
        return pendingMaintenance.maintenanceList.filter( row => { return row.maintenanceId === maintenanceId }  );
    }

    const countMaintenances = () => {
        return getMaintenanceList().length;
    }

    const hasMaintenances = () => {
        return countMaintenances() > 0;
    }

    const openImageDialog = (maintenanceId) => {
        setImage(`${appApiEndpoint}/maintenance/${maintenanceId}/image`)
        setIsDialogOpen(true);
    }

    const openMaintenanceWindow = (maintenanceId) => {
        window.open(`${window.location.origin}/maintenanceTracking/${maintenanceId}`, '_blank', 'noopener,noreferrer');
    }

    const handleResendNotificationClicked = async (maintenanceId) => {
        await fetchResendNotification(maintenanceId);
    }

    useEffect(async () => {
        if ( pendingMaintenanceId == null )
            return;
        await fetchMaintenanceById(pendingMaintenanceId);
    }, [pendingMaintenanceId]);

    const fetchResendNotification = async (maintenanceId) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/maintenance/${maintenanceId}/resendMail`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }
            return true;

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchMaintenanceById = async (pendingMaintenanceId) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/maintenance/${pendingMaintenanceId}`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const pendingMaintenance = await response.json();
            setPendingMaintenance(pendingMaintenance);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }


    return (
        pendingMaintenanceId ? <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { mt: 2, mb: 2 },
            }}
            noValidate
            autoComplete="off"
        >
            <Paper elevation={4} style={{padding: 10, marginBottom: 20}}>
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    label="Código de baño"
                    value={pendingMaintenance.itemCodeId}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    label="Fecha"
                    value={pendingMaintenance.expectedDate}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    label="Cliente"
                    value={pendingMaintenance.clientName}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    label="Dirección"
                    value={pendingMaintenance.clientAddress}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        readOnly: true,
                    }}
                    fullWidth
                    label="Operador"
                    value={pendingMaintenance.operatorName}
                />
            </Paper>

            {getMaintenanceList().map((maintenance) => {
                return <Paper elevation={4} style={{padding: 10, marginBottom: 20}} key={maintenance.maintenanceId}>
                    <TextField
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Operador"
                        value={maintenance.operatorName}
                    />
                    <TextField
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Dirección"
                        value={maintenance.address}
                    />
                    <TextField
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Fecha"
                        value={maintenance.maintenanceDate}
                    />
                    <TextField
                        InputLabelProps={{shrink: true}}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        multiline
                        maxRows={6}
                        label="Comentario"
                        value={maintenance.comment}
                    />
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            readOnly: true,
                        }}
                        fullWidth
                        label="Operador"
                        value={maintenance.operatorName}
                    />
                    {maintenance.position &&
                        <MapContainer center={maintenance.position} zoom={13} dragging={false}
                                      style={{height: 200, marginBottom: 10}}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={maintenance.position}>
                            </Marker>
                        </MapContainer>}
                    <Button variant="outlined" style={{marginRight: 10}} onClick={() => handleResendNotificationClicked(maintenance.maintenanceId)}>Reenviar
                        notificación</Button>
                    <Button variant="outlined" style={{marginRight: 10}}  onClick={() => openMaintenanceWindow(maintenance.maintenanceId) }>Ver aviso</Button>
                    {maintenance.image &&
                        <Button variant="outlined" onClick={() => openImageDialog(maintenance.maintenanceId)}>Ver imagen</Button>}

                </Paper>
            })
            }
            {
            <Dialog onClose={() => setIsDialogOpen(false)} open={isDialogOpen}>
                <DialogTitle>Imagen</DialogTitle>
                <Paper variant="outlined">
                    <img src={image}/>
                </Paper>
            </Dialog>
            }
        </Box> : <Typography>
            Seleccione una mantención
        </Typography>

    )
}