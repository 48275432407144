import {useAuth0} from "@auth0/auth0-react";
import {useConfig} from "../hooks/useConfig";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {FormGroup} from "@mui/material";
import {
    Autocomplete,
    Box,
    Button, FormControl,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PendingMaintenanceDetails from "./PendingMaintenanceDetails";

export function PendingMaintenanceListRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [pendingMaintenanceList, setPendingMaintenanceList] = useState([]);
    const [pendingMaintenanceId, setPendingMaintenanceId] = useState(null);
    const [pendingMaintenance, setPendingMaintenance] = useState({});
    const [currentDate, setCurrentDate] = useState(new Date());
    const [client, setClient] = useState(null);
    const [clientAddress, setClientAddress] = useState("");
    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        (async () => await fetchPendingMaintenanceList(currentDate))();
        (async () => await fetchClientList())();


    }, [currentDate]);

    const fetchClientList = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/client/list`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const clientList = await response.json();
            setClientList(clientList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchPendingMaintenanceAssign = async (currentDate) => {
        const token = await getAccessTokenSilently();
        const dateStr = ( new AdapterDateFns() ).formatByString(currentDate,"yyyy-MM-dd");
        try {

            let url = `${appApiEndpoint}/pendingMaintenance/assign`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify({
                    date: dateStr
                })
            });

            if ( !response.ok ) {
                throw response;
            }

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }
    }

    const fetchPendingMaintenanceList = async (currentDate) => {

        const dateStr = ( new AdapterDateFns() ).formatByString(currentDate,"yyyy-MM-dd");
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/pendingMaintenance/list/${dateStr}`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const pendingMaintenanceList = await response.json();
            setPendingMaintenanceList(pendingMaintenanceList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchPendingMaintenanceList1 = async (currentDate) => {

        const dateStr = ( new AdapterDateFns() ).formatByString(currentDate,"yyyy-MM-dd");
        const client1 = client.clientId;
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/pendingMaintenance/list1/${dateStr}/${client1}`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const pendingMaintenanceList = await response.json();
            setPendingMaintenanceList(pendingMaintenanceList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const handleAssignButtonClicked1 = async () => {
        await fetchPendingMaintenanceAssign(currentDate);
        await fetchPendingMaintenanceList1(currentDate);
    }

    const handleAssignButtonClicked = async () => {

        await fetchPendingMaintenanceAssign(currentDate);
       await fetchPendingMaintenanceList(currentDate);

    }

    const handleRowClicked = async(pendingMaintenanceId, maintenanceId) => {
        console.log(pendingMaintenanceId);
        console.log(maintenanceId);
        setPendingMaintenance({pendingMaintenanceId, maintenanceId});
    }



    const handleChangeCurrentDate = (newValue) => {
        setCurrentDate(newValue);
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Mantenciones
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                        <DesktopDatePicker
                            label="Día"
                            inputFormat="yyyy-MM-dd"
                            mask="____-__-__"
                            value={currentDate}
                            onChange={handleChangeCurrentDate}
                            renderInput={(params) => <TextField {...params} />}
                        />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={1}>

                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button"
                            table="export-excel"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Descargar excel"/>


                </Grid>

                        <Button variant="contained" onClick={handleAssignButtonClicked}>Asignar</Button>



                    <Grid item xs={9}></Grid>
                    <Grid item xs={6}>

                        <Grid item xs={4} >
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={clientList}
                                value={client}
                                onChange={(e, v) => { setClient(v) }  }
                                getOptionLabel={(option) => option.clientName}
                                isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
                                renderInput={(params) => <TextField {...params} label="Cliente" />}

                            />


                            <Button variant="contained" onClick={handleAssignButtonClicked1}>Filtrar</Button>
                                <Button variant="contained" onClick={handleAssignButtonClicked}>Limpiar</Button>

                        </Grid>
                        <TableContainer>
                            <Table id="export-excel" sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Codigo</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Dirección</TableCell>
                                        <TableCell>Operador</TableCell>
                                        <TableCell>Fecha</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pendingMaintenanceList.map((row) => (
                                        <TableRow
                                            hover
                                            key={`${row.pendingMaintenanceId}_${row.maintenanceId}`}
                                            onClick={(event) => {
                                                console.log(row);
                                                handleRowClicked(row.pendingMaintenanceId, row.maintenanceId)
                                            }}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.itemCodeId}
                                            </TableCell>
                                            <TableCell>{row.clientName}</TableCell>
                                            <TableCell>{row.clientAddress}</TableCell>
                                            <TableCell>{row.operatorName}</TableCell>
                                            <TableCell>{row.maintenanceDate}</TableCell>
                                            <TableCell>{
                                                row.maintenanceDate ? <CheckIcon/> :  <AccessTimeIcon />
                                            }</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h5" component="h5">
                            Detalle
                        </Typography>
                        <PendingMaintenanceDetails
                            pendingMaintenanceId={pendingMaintenance.pendingMaintenanceId}
                            maintenanceId={pendingMaintenance.maintenanceId}
                        />
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}