import {useAuth0} from "@auth0/auth0-react";
import {useConfig} from "../hooks/useConfig";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {
    Box,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";

export function ClientListRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [clientList, setClientList] = useState([]);

    useEffect(() => {
        (async () => await fetchClientList())();
    }, []);

    const fetchClientList = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/client/list`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const clientList = await response.json();
            setClientList(clientList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchClientDelete = async (clientId) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/client/${clientId}/delete`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if ( !response.ok ) {
                throw response;
            }

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }
    }

    const handleCreateButtonClicked = async () => {
        await navigate('/client/create');
    }

    const handleRowClicked = async (clientId) => {
        console.log(clientId);
    }

    const handleDeleteButtonClicked = async (clientId) => {
        await fetchClientDelete(clientId);
        await fetchClientList();
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Lista de clientes
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={handleCreateButtonClicked}>Crear cliente</Button>

                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nombre</TableCell>
                                        <TableCell>Correo</TableCell>
                                        <TableCell>Dirección</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {clientList.map((row) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleRowClicked(row.clientId)}
                                            key={row.clientId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.clientName}
                                            </TableCell>
                                            <TableCell>{row.clientMail}</TableCell>
                                            <TableCell>{row.clientAddress}</TableCell>
                                            <TableCell><Button variant="contained" onClick={() => handleDeleteButtonClicked(row.clientId)}>Borrar</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}