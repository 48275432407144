import {useAuth0} from "@auth0/auth0-react";
import {useConfig} from "../hooks/useConfig";
import {useNavigate} from "react-router";
import {useEffect, useState} from "react";
import {
    Autocomplete,
    Box,
    Button,
    Grid, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Tabs, TextField,
    Typography
} from "@mui/material";


export function WeeklyMaintenanceListRoute() {

    const { getAccessTokenSilently} = useAuth0();
    const { appApiEndpoint } = useConfig();
    const navigate = useNavigate();

    const [weeklyMaintenanceList, setWeeklyMaintenaceList] = useState([]);
    const [dayOfWeek, setDayOfWeek] = useState(1);
    const [clientList, setClientList] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [operatorList, setOperatorList] = useState([]);
    const [item, setItem] = useState(null);
    const [client, setClient] = useState(null);
    const [clientAddress, setClientAddress] = useState("");
    const [operator, setOperator] = useState(null);


    useEffect(() => {
        (async () => await fetchWeeklyMaintenanceList(dayOfWeek))();
        (async () => await fetchItemList())();
        (async () => await fetchClientList())();
        (async () => await fetchOperatorList())();
    }, []);

    const fetchItemList = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/item/list`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const itemList = await response.json();
            setItemList(itemList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchOperatorList = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/operator/list`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const operatorList = await response.json();
            setOperatorList(operatorList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchClientList = async () => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/client/list`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const clientList = await response.json();
            setClientList(clientList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const fetchWeeklyMaintenanceDelete = async (weeklyMaintenanceId) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/weeklyMaintenance/${weeklyMaintenanceId}/delete`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            if ( !response.ok ) {
                throw response;
            }

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }
    }

    const fetchWeeklyMaintenanceAssignOperator = async (weeklyMaintenanceId, operatorId) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/weeklyMaintenance/${weeklyMaintenanceId}/assign`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    operatorId
                })
            });

            if ( !response.ok ) {
                throw response;
            }

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }
    }

    const fetchWeeklyMaintenanceCreate = async (itemId, clientId, dayOfWeek) => {
        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/weeklyMaintenance/create`;
            const response = await fetch(url, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'

                },
                body: JSON.stringify({
                    itemId,
                    clientId,
                    dayOfWeek,
                    clientAddress
                })
            });

            if ( !response.ok ) {
                throw response;
            }

            await response.json();

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }
    }

    const fetchWeeklyMaintenanceList = async (dayOfWeek) => {

        const token = await getAccessTokenSilently();

        try {

            let url = `${appApiEndpoint}/weeklyMaintenance/list/${dayOfWeek}`;
            const response = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,

                }
            });

            if ( !response.ok ) {
                throw response;
            }

            const weeklyMaintenanceList = await response.json();
            setWeeklyMaintenaceList(weeklyMaintenanceList);

        } catch (exception) {
            alert("A ocurrido un error: " + exception.message);
        }

    }

    const handleCreateButtonClicked = async () => {
       await fetchWeeklyMaintenanceCreate(item.itemId, client.clientId, dayOfWeek);
       await fetchWeeklyMaintenanceList(dayOfWeek);
    }

    const handleDeleteButtonClicked = async (weeklyMaintenanceId) => {
        await fetchWeeklyMaintenanceDelete(weeklyMaintenanceId);
        await fetchWeeklyMaintenanceList(dayOfWeek);
    }

    const handleAssignButtonClicked = async (weeklyMaintenanceId, operatorId) => {
        await fetchWeeklyMaintenanceAssignOperator(weeklyMaintenanceId, operatorId);
        await fetchWeeklyMaintenanceList(dayOfWeek);
    }


    const handleRowClicked = async (weeklyMaintenanceId) => {
        console.log(weeklyMaintenanceId);
    }


    const handleChangeDayTab = (newValue) => {
        setDayOfWeek(newValue);
        fetchWeeklyMaintenanceList(newValue);
    }

    return (
        <>
            <Typography variant="h5" component="h5">
                Planificación
            </Typography>
            <Box
                component="form"
                noValidate
                autoComplete="off"
            >
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={itemList}
                            value={item}
                            onChange={(e, v) => setItem(v)}
                            getOptionLabel={(option) => option.itemCodeId}
                            isOptionEqualToValue={(option, value) => option.itemId === value.itemId}
                            renderInput={(params) => <TextField {...params} label="Item" />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={clientList}
                            value={client}
                            onChange={(e, v) => { setClient(v); setClientAddress(v.clientAddress);  } }
                            getOptionLabel={(option) => option.clientName}
                            isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
                            renderInput={(params) => <TextField {...params} label="Cliente" />}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            fullWidth
                            label="Dirección"
                            value={clientAddress}
                            onChange={(e) => { setClientAddress(e.target.value) } }
                        />
                    </Grid>
                    <Grid item xs={1}>

                        <Button variant="contained" onClick={handleCreateButtonClicked}>Crear entrada</Button>

                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={operatorList}
                            value={operator}
                            onChange={(e, v) => setOperator(v)}
                            getOptionLabel={(option) => option.operatorName}
                            isOptionEqualToValue={(option, value) => option.operatorId === value.operatorId}
                            renderInput={(params) => <TextField {...params} label="Operador" />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={dayOfWeek - 1} onChange={(event, value) => handleChangeDayTab(Number(value) + 1)} aria-label="basic tabs example">
                                <Tab label="Lunes" />
                                <Tab label="Martes" />
                                <Tab label="Miércoles"/>
                                <Tab label="Jueves" />
                                <Tab label="Viernes" />
                                <Tab label="Sabados" />
                                <Tab label="Domingos " />
                            </Tabs>
                        </Box>
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Codigo</TableCell>
                                        <TableCell>Cliente</TableCell>
                                        <TableCell>Dirección</TableCell>
                                        <TableCell>Operador</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {weeklyMaintenanceList.map((row) => (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleRowClicked(row.weeklyMaintenanceId)}
                                            key={row.weeklyMaintenanceId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.itemCodeId}
                                            </TableCell>
                                            <TableCell>{row.clientName}</TableCell>
                                            <TableCell>{row.clientAddress}</TableCell>
                                            <TableCell>{row.operatorName}</TableCell>
                                            <TableCell><Button variant="contained" onClick={() => handleAssignButtonClicked(row.weeklyMaintenanceId, operator.operatorId)}>Asignar</Button><Button variant="contained" onClick={() => handleDeleteButtonClicked(row.weeklyMaintenanceId)}>Borrar</Button></TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>

            </Box>
        </>
    )
}